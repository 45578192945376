<template>
  <section class="custom-background pb-12 overflow-x-hidden">
    <a-spin :spinning="loading">
      <div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- 「検索結果」ページタイトル -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div style="width: 1240px"
              class="pl-4 mx-auto w-full z-30 relative mt-10"
        >
          <div class="title custom-title text-2xl">
            {{ $t('RESULT_SEARCH') }}
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 選択カテゴリと検索文字列とNFT一覧 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- 選択カテゴリと検索文字列 -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div style="width: 1240px" class="pl-8 mx-auto w-full z-30 relative mb-7
                text-black text-xl"
        >
            カテゴリ：{{ object_category.category_display }} &emsp;
            検索："{{ string_for_search }}"
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ NFT一覧 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          class="mx-auto width-1140"
        >
          <CardList
            @onPageChange="onPageChange"
            :data="list"
            :message="emptyMessage()"
          ></CardList>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ NFT一覧 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 選択カテゴリと検索文字列とNFT一覧 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      </a-spin>
  </section>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// １ページに表示する画像数。
import { PICTURE_PER_PAGE }     from '@/constants/common.constant';
// NFT一覧表示用。
import CardList                 from '@/components/nft/CardList/index.vue';
import { mapActions, mapState } from 'vuex';
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'page-search-result',
  // ====+=========+=========+=========+=========+=========+=========+=========E
  components: { CardList },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      id      : this.$route.params.id,
      loading : false,
      creators: []   ,
      page    :  1   ,
    }; // End of return {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  metaInfo: {
    title: 'BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO',
    // meta: [
    //     {
    //         vmid: "description",
    //         property: "description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //     },
    //     {
    //         property: "og:title",
    //         content: "BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO",
    //         vmid: "og:title",
    //     },
    // ],
  }, // End of metaInfo: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    ...mapState({
      // store にセットした、言語モード（日本語／英語）。
      locale: (state) => state?.setting.locale,
      // store にセットした、ヘッダのカテゴリコンボボックスの選択値。
      object_category  : (state) => state.extractcondition.object_category  ,
      // store にセットした、ヘッダの検索テキストボックスの値。
      string_for_search: (state) => state.extractcondition.string_for_search, 
      // store にセットした、マーケットプレイスのＮＦＴ情報。
      list: (state) => state.marketplace.marketplaceList,
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      // hierarchy: (state) => state.hierarchy.hierarchy,
      // name_en  : (state) => state.hierarchy.name_en,
    }), // End of ...mapState({...})
  }, // End of computed: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  async mounted() {
    // await this.fetchNewPage(1);
    // パンくずリストをヘッダに書くためのコード。現在未使用。
    // this.setHierarchy({hierarchy: this.edition.hierarchy,
    //                      name_en: this.edition.name_en,
    //                      name_ja: this.edition.name_ja,
    // });
  }, // End of  async mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods: {
    ...mapActions({
      // マーケットプレイスのＮＦＴ情報を store に取得。
      fetchMarketplace: 'marketplace/fetchMarketplaceList',
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      setHierarchy: 'hierarchy/setHierarchy',
    }), // ...mapActions({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ページが切り替わったら実行。
    async onPageChange(page) {
      this.page = page;
      await this.fetchNewPage(page);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル projects から
    // レコード情報（オブジェクト）を一ページ分 12 個取得。store に反映。
    async fetchNewPage(page) {
      // alert(`*** START: fetchNewPage: search-result.page.vue ***`);
      // ヘッダのカテゴリコンボボックスの選択肢から、hierarchy の配列を文字列化
      // したものを算出。
      let strArrayHierarchy;
      if (this.object_category.category_id == 0) {
        // カテゴリコンボボックスで「全て」が選択されている。
        strArrayHierarchy = undefined
      } else {
        // カテゴリコンボボックスで「全て」以外が選択されている。
        strArrayHierarchy = JSON.stringify([
          this.object_category.category_name,
          this.object_category.category_name + '\\%',
        ])
      } // End of if (this.category_id == 0) {...} else {...}
      this.loading = true;
      const offset = PICTURE_PER_PAGE * (page - 1);
      await this.fetchMarketplace({
        limit    : PICTURE_PER_PAGE,
        offset   : offset,
        search   : this.string_for_search,
        hierarchy: strArrayHierarchy,
      }); // End of await this.fetchMarketplace({...})
      this.loading = false;
      // alert(`*** END  : fetchNewPage: search-result.page.vue ***`);
    }, // End of async fetchNewPage(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 検索の結果、対象ＮＦＴが無かった場合のメッセージ。
    // 日本語モードと英語モードで異なる。
    emptyMessage() {
        if (this.locale === 'ja') {
            // 日本語モード。
            return '検索条件に一致するNFTは見つかりませんでした。' +
                    '条件を変更して再度検索してください。'
        } else {
            // 英語モード。
            return 'I can\'t find any NFTs which meet the criteria. ' +
                  'Plese change the condition and try again.'
        } // End of if (localse === 'ja') {...} else {...}
    }, // End of emptyMessage() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // this.edition.link の URL を開く。一番上の画面をクリックしたときの処理。
    goToLinkPlace() {
      // window.open(this.edition.link, '_blank');
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  scoped
  lang="scss"
>
.custom-background {
    background-color: white; /* ここで好きな色を指定 */
} 

.width-1140 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}

.text-top {
  @apply text-2xl;
  @screen xxs {
    @apply text-3xl;
  }
  @screen md {
    @apply text-base;
  }
}
  /* タイトル用の基本スタイル */
  .title {
  display: block;
  font-size: 24px; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  /* color: #FFFFFF; テキストカラー 白 */
  color: #000000; /* テキストカラー 黒 */
  margin-bottom: 10px; /* 下余白 */
  padding: 10px 0; /* 上下パディング */
}

/* 特定のセクション用のカスタムスタイル */
.custom-title {
  border-radius: 8px; /* 角の丸み */
  text-align: left; /* テキストを中央揃えに */
}
::v-deep .ant-breadcrumb {
  span {
    .ant-breadcrumb-link {
      @apply text-white;
      @apply font-medium;
    }
  }
}
</style>
